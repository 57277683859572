<template>
  <b-row
    class="vh-100 px-0"
    style="position: relative; overflow: hidden;"
  >

    <b-img
      :src="require('@/assets/svg/oct-blue.svg')"
      style="position: absolute; left: 10vw; top: 7vh; filter: drop-shadow(5px 5px 5px #ccc);"
    />

    <!-- <b-img
      :src="require('@/assets/svg/oct-red.svg')"
      style="position: absolute; left: 3vw; top: 40vh; filter: drop-shadow(5px 5px 5px #ccc);"
      width="60px"
    /> -->

    <b-img
      :src="require('@/assets/svg/oct-green.svg')"
      style="position: absolute; left: 18vw; top: 60vh; filter: drop-shadow(5px 5px 5px #ccc);"
      width="48px"
    />

    <b-img
      :src="require('@/assets/svg/oct-dark.svg')"
      style="position: absolute; left: 50px; top: 90vh; filter: drop-shadow(5px 5px 5px #ccc);"
      width="32px"
    />

    <b-img
      :src="require('@/assets/svg/oct-red.svg')"
      style="position: absolute; right: 10vw; top: 7vh; filter: drop-shadow(5px 5px 5px #ccc);"
      width="84px"
    />

    <b-img
      :src="require('@/assets/svg/oct-yellow.svg')"
      style="position: absolute; right: 18vw; top: 40vh; filter: drop-shadow(5px 5px 5px #ccc);"
      width="48px"
    />

    <b-img
      :src="require('@/assets/svg/oct-dark.svg')"
      style="position: absolute; right: 50px; top: 90vh; filter: drop-shadow(5px 5px 5px #ccc);"
      width="32px"
    />

    <!-- <b-col
      cols="12"
      md="6"
      class="d-none d-md-flex justify-content-center align-items-center"
    >
      <b-img
        :src="require('@/assets/images/3d/payment.png')"
        style="z-index: 1; transform: rotate(15deg); height: fit-content; width: 75%"
        class="border border-primary"
      />
      <b-card
        class="border border-warning"
        style="width: 45%; height: 45%; position: absolute; transform: rotate(45deg); background: transparent;"
      />

    </b-col> -->
    <b-col
      cols="12"
    >
      <b-container class="h-100 d-flex justify-content-center align-items-center">
        <ValidationObserver
          ref="signinForm"
          v-slot="{ handleSubmit }"
        >
          <form
            role="form"
            @submit.prevent="handleSubmit(onSignIn)"
          >
            <b-card
              noheader
              footer-tag="footer"
              class="bg-white shadow-md"
              text-variant="dark"
              style="max-width: 400px;"
            >
              <b-card-text class="pb-1">
                <b-row>
                  <b-col
                    cols="12"
                    class="pb-4"
                  >
                    <h3 class="mb-0 font-weight-bolder">
                      Login
                    </h3>
                    <h6 class="mb-0">
                      Sign in to your account to continue.
                    </h6>
                  </b-col>
                  <b-col
                    cols="12"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          vid="username"
                          name="username"
                          rules="required"
                        >
                          <b-form-group
                            label="Username"
                            label-for="username"
                            label-class="font-weight-bold"
                            class="mb-2"
                          >
                            <b-input
                              id="username"
                              v-model="credentials.username"
                              type="text"
                              placeholder="enter username here"
                              autocomplete="off"
                              :state="errors.length > 0 ? false : null"
                              :disabled="state.busy"
                            />
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                      <b-col
                        cols="12"
                      >
                        <ValidationProvider
                          #default="{ errors }"
                          vid="password"
                          name="password"
                          rules="required"
                        >
                          <b-form-group
                            label="Password"
                            label-for="password"
                            label-class="font-weight-bold"
                            class="mb-2"
                          >
                            <b-input-group
                              :class="errors.length > 0 ? 'is-invalid' : ''"
                            >
                              <b-input
                                id="password"
                                v-model="credentials.password"
                                placeholder="enter password here"
                                autocomplete="off"
                                :type="passwordType"
                                :state="errors.length > 0 ? false : null"
                                :disabled="state.busy"
                              />
                              <b-input-group-append>
                                <b-button
                                  class="pl-2 pr-1"
                                  variant="secondary"
                                  style="border-radius: 0px 5px 5px 0px; background-color: var(--iq-body-color); border: 1px solid #ccc; border-left: 0px solid transparent;"
                                  :disabled="state.busy"
                                  @click="togglePassword"
                                >
                                  <i
                                    v-if="state.visible_password"
                                    class="ri-eye-line h6"
                                  />
                                  <i
                                    v-else
                                    class="ri-eye-off-line h6"
                                  />
                                </b-button>
                              </b-input-group-append>
                            </b-input-group>
                            <div
                              v-if="errors.length > 0"
                              class="invalid-feedback"
                            >
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                      </b-col>

                    </b-row>
                  </b-col>
                </b-row>
                <b-row class="px-2 pt-3">

                  <b-overlay
                    class="col-12 px-1"
                    rounded
                    opacity="0.1"
                    spinner-variant="warning"
                    spinner-small
                    :show="state.local"
                  >
                    <b-button
                      size="sm"
                      type="submit"
                      class="w-100"
                      variant="primary"
                      :disabled="state.busy"
                    >
                      Sign In
                    </b-button>
                  </b-overlay>

                </b-row>
              </b-card-text>
            </b-card>

          </form>
        </ValidationObserver>
      </b-container>
    </b-col>
  </b-row>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SSharedAuth } from '@/services'
import url from '@/mixins/url'
import logos from '@/mixins/logos'
export default {
  name: 'SharedLogin',

  middleware: ['guest'],

  metaInfo: () => ({
    title: 'Sign In'
  }),

  mixins: [
    url,
    logos
  ],

  data: () => ({
    state: {
      busy: false,
      local: false,
      visible_password: false
    },
    credentials: {
      username: null,
      password: null
    }
  }),

  computed: {

    companyLogo () {
      return this.transformToImageUrl(this.getCompany())
    },

    passwordType () {
      return this.state.visible_password ? 'text' : 'password'
    }

  },

  mounted () {
    core.index()
  },

  methods: {

    togglePassword () {
      this.state.visible_password = !this.state.visible_password
    },

    async saveAndFetchUser (token) {
      this.$store.dispatch('auth/saveToken', token)
      return new Promise((resolve, reject) => {
        SSharedAuth.getUserProfile().then(
          ({ data }) => {
            this.$store.dispatch('auth/fetchUserSuccess', {
              id: data.id,
              email: data.email,
              username: data.username,
              role: data.role,
              name: data.name
            })
            const user = this.$store.getters['auth/user']
            this.$router.replace({
              name: user ? `${user.role}.dashboard` : 'dashboard'
            }, () => {
              resolve(data)
            })
          }
        ).catch(error => {
          this.$store.dispatch('auth/fetchUserFailure')
          reject(error)
        })
      })
    },

    async onSignIn () {
      await this.$refs.signinForm.validate().then(
        allFieldsAreValid => {
          if (allFieldsAreValid) {
            this.state.busy = this.state.local = true
            SSharedAuth.signIn(this.credentials).then(
              ({ data: { token } }) => {
                this.saveAndFetchUser(token).finally(() => {
                  this.state.busy = this.state.local = false
                })
              }
            ).catch(() => {
              this.state.busy = this.state.local = false
            })
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.text-divider {
  margin: 2em 0;
  line-height: 0;
  text-align: center;
  color: #aab840;
  padding: 0px 6px;
}

.text-divider span {
  background-color: white;
  padding: .5em;
}

.text-divider:before {
  content: " ";
  display: block;
  border-top: 1px solid #c7d467;
  border-bottom: 1px solid #f7f7f7;
}
</style>
